import styled from "styled-components";

export const SliderDiv = styled.div`
  font-family: var(--uiFont);
  overflow: hidden;
  margin-top: var(--outsidePadding);
  /* margin-bottom: var(--outsidePadding); */
  transition: 0.5s;
  /* min-height: 360px; */
  & button:focus {
    box-shadow: none;
  }
  & .carousel-status {
    display: none;
  }
  & .control-dots {
    display: none;
  }
  & .carousel .slide {
    background-color: var(--white);
    background-color: transparent;
  }
  & .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }
  & .carousel.carousel-slider .control-arrow {
    color: var(--blue);
  }
  & .carousel .control-prev.control-arrow:before {
    border-right-color: var(--blue);
  }
  & .carousel .control-next.control-arrow:before {
    border-left-color: var(--blue);
  }
  /* & .flickity-prev-next-button {
    top: 35%;
  } */
  /* @media screen and (max-width: 767px) {
    & .flickity-prev-next-button.next {
      right: 0;
      width: 20px;
      padding-right: 0;
      margin-right: 0;
    }
    & .flickity-prev-next-button.previous {
      left: 0;
      width: 20px;
      padding-left: 0;
      margin-right: 0;
    }
  } */
`;

export const Slide = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding-left: 72px;
  padding-right: 72px;
  user-select: none;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  &.center {
    align-items: center;
  }
  & strong {
    color: var(--red);
  }
  @media screen and (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Blurb = styled.blockquote`
  border: 0;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-size: 21px;
  line-height: 30px;
  text-align: left;
  &:before {
    content: "“";
  }
  &:after {
    content: "”";
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Blurber = styled.p`
  text-align: right;
  margin-bottom: var(--bottomPadding);
  font-size: 21px;
  line-height: 30px;
  &:before {
    content: "—";
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

import React from "react";
// import Flickity from "react-flickity-component";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { PageH2 } from "../styledcomponents/layoutstyles";
import {
  Blurb,
  Blurber,
  SliderDiv,
  Slide,
} from "./../styledcomponents/blurbsliderstyles";
// const flickityOptions = {
//   initialIndex: 1,
//   wrapAround: true,
//   adaptiveHeight: true,
//   pageDots: false,
// };

const BlurbSlider = () => {
  return (
    <React.Fragment>
      <PageH2>
        Praise for Eric Giroux’s <em>Ring On Deli</em>:
      </PageH2>
      <SliderDiv>
        <Carousel showThumbs={false} infiniteLoop={true}>
          <Slide className="center">
            <Blurb>This author has talent to burn.</Blurb>
            <Blurber>
              <strong>
                <em>Publishers Weekly</em>
              </strong>
            </Blurber>
          </Slide>
          <Slide>
            <Blurb>
              Giroux’s prose is reminiscent of Richard Russo’s writing:
              intricate and incisive, though always full of warmth and
              humor.&nbsp;.&nbsp;.&nbsp;. A well-balanced comic tale that deftly
              grapples with larger contemporary themes.
            </Blurb>
            <Blurber>
              <strong>
                <em>Kirkus Reviews</em>
              </strong>
            </Blurber>
          </Slide>
          <Slide className="center">
            <p style={{ fontSize: "21px" }}>
              <strong>WINNER</strong>, 2021 National Indie Excellence Award
              (Comedy)
            </p>
          </Slide>
          <Slide>
            <Blurb>
              I devoured <em>Ring On Deli</em> in a kind of helium trance, agog
              at its antic intelligence, its knowing humor, and its generosity
              of heart. Eric Giroux’s Pennacook is a bountiful land indeed, rich
              in local history and drama and small, beleaguered institutions,
              along with those small, beleaguered humans who against
              considerable odds (and herds of roaming boars) keep them going. It
              has the charm of some heady contemporary fable, in which the whole
              business model we call America is transformed back into some
              weirdly plausible, even utopian experiment.
            </Blurb>
            <Blurber>
              <strong>Robert Cohen</strong>, author of{" "}
              <em>Amateur Barbarians</em>
            </Blurber>
          </Slide>
          <Slide>
            <Blurb>
              From a small town budget referendum to a supermarket revolution to
              roving packs of wild boar. Like his Yankee forebears Tom Perrotta
              and John Irving, Eric Giroux mashes together hot-button social
              issues and wacky slapstick with a keen eye and a big heart.{" "}
              <em>Ring On Deli</em> is a zany, high-energy, absolutely
              satisfying satire of American appetites.
            </Blurb>
            <Blurber>
              <strong>Stewart O’Nan</strong>, author of{" "}
              <em>Last Night at the Lobster</em>
            </Blurber>
          </Slide>
          <Slide>
            <Blurb>
              <em>Ring On Deli</em> is a funny and imaginative riff on the real
              life story of Market Basket, in which millions protested the
              ouster of the supermarket’s beloved CEO. Set in a small New
              England town, <em>Ring On Deli</em> is an off-beat primer on
              American business which offers a heartwarming and engaging story
              of people willing to fight to protect an institution.
            </Blurb>
            <Blurber>
              <strong>Daniel Korschun</strong>, co-author of{" "}
              <em>
                We Are Market Basket:
                <br />
                The Story of the Unlikely Grassroots Movement
                <br /> that Saved a Beloved Business
              </em>
            </Blurber>
          </Slide>
          <Slide>
            <Blurb>
              It’s the Great Recession, and feral pigs are roaming the shuttered
              streets of Pennacook. As big business arrives to claim Bounty Bag,
              the town’s family-owned grocery store, brothers Ray and Patrick
              find themselves taking sides in a fight that alternately divides
              and unites their friends and neighbors. Tackling important
              socioeconomic issues with equal parts humor and compassion, Giroux
              has a comic’s sense of timing, a journalist’s eye for detail, and
              a poet’s love of language. We need more novels like{" "}
              <em>Ring On Deli</em>, perhaps now more than ever.
            </Blurb>
            <Blurber>
              <strong>David Eric Tomlinson</strong>, author of{" "}
              <em>The Midnight Man</em>
            </Blurber>
          </Slide>
          <Slide className="center">
            <Blurb>Rowsing and rewarding</Blurb>
            <Blurber>
              <strong>
                <em>Foreword Reviews</em>
              </strong>
            </Blurber>
          </Slide>
          <Slide>
            <Blurb>
              [A] rich, incisive story that vividly illustrates the inequities
              in the American economy and offers a serious look at those
              fighting to hold on to what they have and those who join them in
              the fight. Whether you call it a page-turner, a must-read, or a
              story you can’t put down at night, <em>Ring On Deli</em> lives up
              to every enthusiastically positive description about a novel.
            </Blurb>
            <Blurber>
              <strong>
                <em>IndieReader</em>
              </strong>{" "}
              (5 out of 5 stars, <em>IndieReader Approved</em>,<br />a{" "}
              <em>“Best-Reviewed Books—July 2020”</em>)
            </Blurber>
          </Slide>
        </Carousel>
      </SliderDiv>
    </React.Fragment>
  );
};

export default BlurbSlider;
